<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Новый товар</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'item.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import ItemCreateForm from '@/model/item/ItemCreateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemCreatePage',
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: '',
          isActive: false
        },
        rule: {
          name: [ruleRequired()]
        }
      }
    }
  },
  computed: {
    ...mapState('item_create',['busy'])
  },
  methods: {
    ...mapActions('item_create',['createItem']),
    submitForm() {
      const form = ItemCreateForm.create(this.form.data)

      this.createItem({ form })
        .then((id) => this.$router.push({ name: 'item.view', params: { id } }))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    }
  }
}
</script>

<style scoped>

</style>
